import React, { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import axios from 'axios';
import { env } from '../utilities/function';
import { useLocation } from 'react-router-dom';

const authorizeContext = createContext();

const AuthorizationProvider = ({ children }) => {
    const [content, setContent] = useState(<Loading message='Please wait, logging you in...' />);
    const [user, setUser] = useState({});
    const location = useLocation();

    const authorize = async (loggedIn, cb) => {
        if (loggedIn) {
            setContent(children);
        } else {
            const redirectTo =
                env('AUTHENTICATION_CLIENT') +
                '/login?redirectto=' +
                encodeURIComponent(env('DOMAIN') + location.pathname + location.search);

            setContent(
                <Loading
                    message='Please wait, redirecting you to Catch Accounts'
                    redirectTo={redirectTo}
                />
            );
        }
        if (typeof cb === 'function') cb(setUser);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`/profile`);
                const user = response.data.user;

                authorize(true, setUser => setUser(user));
            } catch (err) {
                console.log(err);
                authorize(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <authorizeContext.Provider value={{ authorize, setUser, user }}>
            {content}
        </authorizeContext.Provider>
    );
};

const useAuthorize = () => useContext(authorizeContext).authorize;
const useUser = () => useContext(authorizeContext)?.user;

export default AuthorizationProvider;
export { useAuthorize, useUser };
