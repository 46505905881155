// Imports
import { Route, Routes, useLocation } from 'react-router-dom';

// Components
import Footer from './layouts/Footer';

// Pages
import Main from './pages/Main';

// Styling
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/custom.css';
import './style/global.css';
import './utilities/axios';

import AuthorizeRouter from './router/AuthorizeRouter';
import Navbar from './layouts/Navbar';
import { useEffect } from 'react';
import ApplyForm from './pages/Main/ApplyForm';

function App() {
    return (
        <>
            <Navbar>
                <ScrollToTop />
                <Routes>
                    <Route path='/' element={<Main />} />
                    <Route path='/:id' element={<AuthorizeRouter />}>
                        <Route index element={<ApplyForm />} />
                    </Route>
                </Routes>
            </Navbar>
            <Footer />
        </>
    );
}

function ScrollToTop() {
    const location = useLocation();

    useEffect(
        function () {
            window.scrollTo(0, 0);
            console.log('Route Changed');
        },
        [location.pathname]
    );

    useEffect(() => {
        console.log('Hash Changed');
        const hash = location.hash;
        const el = hash && document.getElementById(hash.slice(1));
        if (el) {
            const coors = el.getBoundingClientRect();
            const scrollPosY = window.scrollY + coors.y - 58;
            window.scrollTo(0, scrollPosY);
        }
    }, [location.hash]);

    return null;
}

export default App;
