import { Form, InputGroup } from 'react-bootstrap';
import { HiLocationMarker } from 'react-icons/hi';
import { FaSearch } from 'react-icons/fa';
import { BiBriefcase } from 'react-icons/bi';
import Hero from '../../components/Hero';
import React, { useEffect, useState } from 'react';
import JobCard from './JobCard';
import axios from 'axios';

const Main = () => {
    const [jobs, setJobs] = useState([]);

    async function fetchJobs() {
        try {
            const response = await axios.get(`/open/job-listing/?sortBy=order`);

            const jobs = response.data.jobs;

            setJobs(jobs);
        } catch (e) {}
    }

    useEffect(() => {
        fetchJobs();
    }, []);

    return (
        <>
            <Hero
                heading={<>Join Our Fast Growing Company</>}
                content='Discover your potential and build a rewarding career with us'
                image='homepage.jpg'
                primaryButton='Get Started'
                primaryLink='#features'
                secondaryButton='  Our story'
                secondaryLink='#story'
            />

            <hr
                style={{
                    width: '100px',
                    border: '1px solid rgb(0 118 215)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    opacity: '1',
                }}
                id='features'
            />

            <h5 className='text-center mb-4' style={{ letterSpacing: '7px', wordSpacing: '10px' }}>
                OPEN ROLES
            </h5>
            <h1
                className='text-center mb-5 pb-5'
                style={{ fontWeight: '900', fontSize: 'xxx-large' }}>
                Join Our Growing Team
            </h1>

            <div
                className='mb-5 d-md-flex d-block'
                // style={{ display: { md: 'flex', xs: 'block' } }}
            >
                <InputGroup className='mb-2'>
                    <SearchInput placeholder='Job Title' icon={FaSearch} />
                </InputGroup>
                <InputGroup className='mb-2'>
                    <SearchInput placeholder='Job Type' icon={BiBriefcase} />
                </InputGroup>
                <InputGroup className='mb-2'>
                    <SearchInput placeholder='Job Location' icon={HiLocationMarker} />
                </InputGroup>

                <InputGroup.Text
                    className='p-3'
                    style={{
                        height: '45px',
                        color: 'white',

                        background: 'var(--bs-primary)',
                        borderColor: 'var(--bs-primary)',
                    }}>
                    Search
                </InputGroup.Text>
            </div>

            <div className='mb-5'>
                {jobs.map((job, i) => (
                    <JobCard key={i} job={job} />
                ))}
            </div>
        </>
    );
};

function SearchInput({ placeholder, icon }) {
    return (
        <>
            <span
                className='input-group-text'
                id='basic-addon1'
                style={{
                    background: 'none',
                }}>
                {' '}
                {React.createElement(icon)}
            </span>
            <Form.Control
                placeholder={placeholder}
                aria-label={placeholder}
                style={{
                    width: '30px',
                    height: '45px',
                    position: 'relative',
                }}
            />
        </>
    );
}

export default Main;
