import { getCookie } from './cookies';

function toKebabCase(str) {
    return str.replace(/[A-Z ]/g, str => (str === ' ' ? '-' : str.toLowerCase()));
}

const link = path => process.env.REACT_APP_MAIN_SITE + path;

const domain = path => 'https://' + path + '.' + process.env.REACT_APP_MAIN_SITE;

const env = name => {
    const nodeENV = process.env.NODE_ENV.toUpperCase();

    return process.env[`REACT_APP_${nodeENV}_${name}`] || process.env[`REACT_APP_${name}`];
};

const isLoggedIn = () => {
    const a = getCookie('accessToken');
    return a;
};

const redirectToAuth = (path = '') => {
    window.location.href =
        env('AUTHENTICATION_CLIENT') +
        '/login?redirectto=' +
        encodeURIComponent(process.env.REACT_APP_DOMAIN + path);
};

const isEmpty = obj => Object.keys(obj).length === 0;

export { toKebabCase, link, domain, env, isLoggedIn, redirectToAuth, isEmpty };
