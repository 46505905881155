import React, { useReducer } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const snackReducer = function (state, action) {
    if (!action) return { show: false, severity: state.severity };
    const severity = Object.keys(action)[0];
    const message = Object.values(action)[0];
    return { show: true, severity, message };
};

export default function useSnack() {
    const [snack, showMessage] = useReducer(snackReducer, { show: false });
    return {
        SnackBar: (
            <ToastContainer className='p-3' position='bottom-start' containerPosition='fixed'>
                <Toast
                    onClose={() => showMessage(null)}
                    show={snack.show}
                    delay={4000}
                    autohide
                    bg={snack.severity}>
                    <Toast.Body
                        style={{ color: snack.severity === 'danger' ? 'white' : 'inherit' }}>
                        {snack.message}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        ),
        showMessage,
    };
}
// <Snackbar
//     open={snack.show}
//     autoHideDuration={3000}
//     onClose={() => showMessage(null)}>
//     <Alert color={snack.severity} severity={snack.severity}>
//         {snack.message}
//     </Alert>
// </Snackbar>
