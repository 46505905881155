import { Accordion, Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import { HiLocationMarker } from 'react-icons/hi';
import { FaBriefcase } from 'react-icons/fa';
import { BiCategory, BiChevronDown } from 'react-icons/bi';
import React, { useRef } from 'react';
import { BsClock } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function JobCard({ job }) {
    const collapseRef = useRef(null);
    const navigate = useNavigate();

    function showDetails() {
        if (!collapseRef.current) return;
        const button = collapseRef.current.children[0];
        button.click();
    }

    return (
        <Card style={{ padding: '30px 40px' }} className='mb-3 shadow grow-on-hover'>
            <Card.Title
                className='mb-3'
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/' + job._id)}>
                {job.title}
            </Card.Title>
            <Row className='align-items-center'>
                <Col xs>
                    <Label icon={BiCategory}>{job.department}</Label>
                    <Label icon={BsClock}>{job.jobType}</Label>
                    <Label icon={HiLocationMarker}>{job.location}</Label>
                    <Label icon={FaBriefcase}>{job.experience}+ Years of Experience</Label>
                </Col>
                <Col xs='auto'>
                    <ButtonGroup
                        className='align-items-center me-3 accordion-button'
                        eventkey='0'
                        style={{ cursor: 'pointer' }}>
                        <Button
                            className='pe-1'
                            style={{
                                background: 'transparent',
                                border: 'none',
                                color: 'inherit',
                                fontSize: '20px',
                            }}
                            onClick={showDetails}>
                            Show Details
                        </Button>
                        <BiChevronDown />
                    </ButtonGroup>
                </Col>
                <Col xs='auto'>
                    <Button
                        variant='primary'
                        onClick={() => navigate(`/${job._id}`)}
                        style={{
                            fontSize: '20px',
                        }}>
                        Apply Now
                    </Button>
                </Col>
            </Row>
            <Accordion>
                <Accordion.Item eventKey='0' style={{ '--bs-accordion-border-width': 'none' }}>
                    <Accordion.Header ref={collapseRef} style={{ display: 'none' }}>
                        Don't look at me
                    </Accordion.Header>
                    <Accordion.Body className='px-0'>
                        <hr />
                        {job.details.map((detail, i) =>
                            React.createElement(
                                detail.tag,
                                {
                                    key: i,
                                    style: {
                                        ...(detail.tag.charAt(0).toLowerCase() === 'h'
                                            ? { paddingTop: '1.5rem' }
                                            : {}),
                                    },
                                },
                                detail.content
                            )
                        )}
                        <div className='text-center'>
                            <Button
                                variant='primary'
                                className='mt-3'
                                onClick={() => navigate(`/${job._id}`)}
                                style={{
                                    fontSize: '20px',
                                }}>
                                Apply Now
                            </Button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card>
    );
}

function Label({ children, icon }) {
    return (
        <ButtonGroup
            style={{ fontWeight: 500, fontSize: '20px' }}
            className='align-items-center me-5'>
            {React.createElement(icon)}
            <small className='p-0 m-0 ms-2'>{children}</small>
        </ButtonGroup>
    );
}

export default JobCard;
