import BootstrapImage from 'react-bootstrap/Image';

const Image = props => {
    const { src, ...rest } = props;

    const link = src && process.env.PUBLIC_URL + '/images/' + src;

    return <BaseImage src={link} {...rest} />;
};

function CdnImage(props) {
    const { src, ...rest } = props;

    const link = src && process.env.REACT_APP_CDN_SERVER + '/images/' + src;

    return <BaseImage src={link} {...rest} />;
}

function BaseImage(props) {
    const { center, ...rest } = props;

    const image = props.src && <BootstrapImage alt='Site' fluid {...rest} />;

    return center ? <div style={{ textAlign: 'center' }}>{image}</div> : image;
}

export { Image, CdnImage };

export default Image;
