import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthorizationProvider from '../hooks/Authorize';

const AuthorizeRouter = () => {
    return (
        <AuthorizationProvider>
            <Outlet />
        </AuthorizationProvider>
    );
};

export default AuthorizeRouter;
