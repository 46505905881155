// import React, { useState } from 'react';s
// import { Button, Form, Stack } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import Image from './Image';

const Hero = props => {
    const { heading, content, image } = props;

    return (
        <Row
            style={{ minHeight: 'calc(100vh - 60px)' }}
            className='align-items-center my-5 my-lg-0'
            id='hero'>
            <Col
                xs={12}
                lg={5}
                className='d-inline-flex justify-content-center flex-column'>
                <div className='text-center text-lg-start'>
                    <div className='mx-3 mx-sm-0'>
                        <h1
                            style={{
                                fontSize: 'clamp(30px, 5vw, 60px)',
                                fontWeight: 400,
                            }}>
                            {heading}
                        </h1>
                        <p style={{ marginBlock: '32px' }}>{content}</p>
                    </div>

                    {/* <Stack
                        direction='vertical'
                        gap={3}
                        className='d-inline-flex justify-content-center flex-md-row  justify-content-lg-start'>
                        <Form.Group controlId='formBasicEmail'>
                            <Form.Control
                                type='email'
                                placeholder='Enter email'
                                value={email}
                                required
                                onChange={handleChange}
                                style={{
                                    borderRadius: '4px',
                                    padding: '0.6em 0.8em',
                                }}
                            />
                        </Form.Group>

                        {primaryButton && (
                            <Button variant='primary section-button' onClick={redirect}>
                                {primaryButton}
                            </Button>
                        )}
                    </Stack>

                    {error && (
                        <p
                            style={{
                                color: '#d32f2f',
                                fontWeight: '300',
                                fontSize: '13px',
                                paddingTop: '5px',
                                paddingLeft: '8px',
                            }}>
                            {error}
                        </p>
                    )}

                    <div className='mt-5'>
                        Don't have an account?{' '}
                        <a href={primaryLink} className='ms-sm-3 d-block d-sm-inline my-4'>
                            Sign up at no cost
                        </a>
                    </div> */}
                </div>
            </Col>

            <Col
                xs={12}
                lg={7}
                className='text-right '
                style={{ textAlign: 'right' }}>
                <Image src={image} />
            </Col>
        </Row>
    );
};

export default Hero;
