import React from 'react';
import { Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { AiFillYoutube, AiFillFacebook, AiFillQuestionCircle } from 'react-icons/ai';

import { BsTwitter } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { link } from '../utilities/function';

const Footer = () => {
    return (
        <footer
            style={{
                background: '#F8F9FA',
                paddingTop: '50px',
                paddingBottom: '32px',
            }}>
            <Container>
                <Stack direction='horizontal' style={{ alignItems: 'center' }}>
                    <div>Follow our Blog</div>
                    <AiFillYoutube size='25px' className='mx-3' />
                    <BsTwitter size='25px' className='mx-3' />
                    <AiFillFacebook size='25px' className='mx-3' />
                </Stack>
                <hr
                    style={{
                        border: '1px solid rgba(0,0,0,0.3)',
                        marginBlock: '32px',
                    }}
                />
                <Row className='flex-wrap align-items-center' style={{ whiteSpace: 'nowrap' }}>
                    <Col xs={12} lg={7}>
                        <Row className='flex-wrap align-items-center my-2'>
                            <Col xs={12} sm='auto'>
                                <Link to={link('/')} className='link-dark h5 mb-0 p-2'>
                                    Catch
                                </Link>
                            </Col>
                            {/* <Col xs='auto'>
                                <Link className='p-2 link-dark pl-0 mb-0' to={link('/')}>
                                    About Catch
                                </Link>
                            </Col>
                            <Col xs='auto'>
                                <Link
                                    to={link('/catch-products')}
                                    className='p-2 link-dark pl-0 mb-0'>
                                    Catch Products
                                </Link>
                            </Col> */}
                            <Col xs='auto'>
                                <Link
                                    className='p-2 link-dark pl-0 mb-0'
                                    to={process.env.REACT_APP_DOMAIN}>
                                    Careers
                                </Link>
                            </Col>
                            <Col xs='auto'>
                                <Link to={link('/privacy')} className='p-2 link-dark pl-0 mb-0'>
                                    Privacy
                                </Link>
                            </Col>
                            <Col xs='auto'>
                                <Link to={link('/terms')} className='p-2 link-dark pl-0 mb-0'>
                                    Terms
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={5}>
                        <Row className='align-items-center my-2'>
                            <Col>
                                <a
                                    href='#link'
                                    style={{
                                        color: 'inherit',
                                        float: 'right',
                                    }}>
                                    <AiFillQuestionCircle size='24px' className='mx-3' />
                                    <span className='h6 mb-0'>Help</span>
                                </a>
                            </Col>
                            <Col>
                                <Form.Select
                                    aria-label='Default select example'
                                    style={{ background: 'inherit' }}>
                                    <option>English</option>
                                    <option value='1'>Hindi</option>
                                    <option value='2'>Japanese</option>
                                    <option value='3'>French</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
