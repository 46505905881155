import { Form as BootstrapForm, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form as HookForm, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs';
import { BsGlobe } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';
import { HiOfficeBuilding } from 'react-icons/hi';
import { useCallback, useEffect, useState } from 'react';
import { useMessage } from '../../layouts/Navbar';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Avatar, FormHelperText, Menu, MenuItem, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { env, isEmpty } from '../../utilities/function';
import { useUser } from '../../hooks/Authorize';
import { clearCookie } from '../../utilities/cookies';
const countryCodes = require('country-codes-list');
const myCountryCodesObject = countryCodes.customList(
    'countryCallingCode',
    '[{countryCode}] {countryNameEn}: +{countryCallingCode}'
);

function ApplyForm() {
    const [show, setShow] = useState(false);
    const jobId = useParams().id;
    const handleOpenPopUp = () => setShow(true);
    const handleClosePopUp = () => {
        setShow(false);
        navigate(-1);
    };
    const [job, setJob] = useState({});
    const [checkBox, setCheckBox] = useState(false);
    const navigate = useNavigate();
    const user = useUser();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleClickMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const { showError, showSuccess } = useMessage();

    const fetchJob = useCallback(async function (jobId) {
        const res = await axios.get('/open/job-listing/' + jobId);
        const job = res.data.job;
        setJob(job);
    }, []);

    const handlers = useForm(
        {
            // fullName: { required: true },
            jobTitle: {
                required: true,
                validator: v =>
                    /^[a-zA-Z ]+$/.test(v) ? '' : 'No numbers or Special Symbols are allowed',
            },
            experience: { required: true },
            // email: { required: true },
            countryCode: { required: true },
            phone: { required: true },
            linkedinAccount: {
                required: false,
                validator: v => (/(http:\/\/|https:\/\/)/.test(v) ? '' : 'Enter valid url'),
            },
            photo: { required: true },
            resume: {
                required: true,
            },
        },
        {
            Input: CustomInput,
        }
    );

    const handleResumeChange = e => {
        const { files } = e.target;
        const file = files[0];
        const isValidExtension = [
            'PDF',
            'DOC',
            'DOCX',
            'DOCS',
            'TXT',
            'PNG',
            'JPEG',
            'JPG',
            'AVIF',
            'WEBP',
        ].some(ext => new RegExp(`(${ext})$`, 'gi').test(file.name));

        if (!isValidExtension || file.size > 10e6)
            return showError('Please provide the above mentioned file format or size');

        showSuccess('Resume changed successfully');
        return file;
    };

    const handlePhotoChange = e => {
        const { files } = e.target;
        const file = files[0];

        const isValidExtension = ['PNG', 'JPEG', 'JPG', 'AVIF', 'WEBP'].some(ext =>
            new RegExp(`(${ext})$`, 'gi').test(file.name)
        );

        if (!isValidExtension)
            return showError('Please provide the PNG, AVIF, WEBP, JPEG or JPG file format');

        showSuccess('Photo changed successfully');
        return file;
    };

    const errors = handlers.errors;

    function onSubmit(res) {
        const { success, errors } = res.data;

        if (success) {
            showSuccess('Applied Successfully');
            handleOpenPopUp();
            return;
        }

        errors.forEach(err => showError(err));
    }

    function onError(err) {
        const { errors } = err.response.data;

        errors.forEach(err => showError(err));
    }

    const handleCloseButton = () => {
        handleClosePopUp();
        navigate(-1);
    };

    useEffect(() => {
        if (jobId) fetchJob(jobId);
    }, [fetchJob, jobId]);

    const logout = () => {
        clearCookie('accessToken');

        // authorize(false);
        const redirectTo =
            env('AUTHENTICATION_CLIENT') +
            '/login?redirectto=' +
            encodeURIComponent(env('DOMAIN') + location.pathname + location.search);
        window.location.replace(redirectTo);
    };

    return !isEmpty(job) ? (
        <>
            <HookForm
                enctype='multipart/form-data'
                handlers={handlers}
                onSubmit={onSubmit}
                final={values => {
                    return {
                        ...values,
                        jobId: job._id,
                    };
                }}
                method='post'
                action={`/user/job-application`}
                onError={onError}>
                <Modal.Header className='w-70 mt-5 mx-auto'>
                    <div className='w-100'>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}>
                            <div onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                                <BiArrowBack /> Back to Job Description
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    onClick={handleClickMenu}
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        objectFit: 'cover',
                                        borderRadius: '50%',
                                        border: '1px solid rgba(0, 0, 0, 0.5)',
                                    }}>
                                    {user?.firstName.charAt(0)}
                                </Avatar>
                                <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
                                    <MenuItem onClick={logout}>
                                        <Typography fontWeight={200}>Logout</Typography>
                                    </MenuItem>
                                </Menu>
                                <Typography variant='subtitle01' ml={1}>
                                    {user.firstName + ' ' + user.lastName}
                                </Typography>
                            </div>
                        </div>
                        <h1 className='mt-3 mb-2' style={{ fontWeight: 700 }}>
                            {job.title}
                        </h1>
                        <p style={{ fontSize: '1.25rem' }}>
                            {job.jobType}, {job.location}
                        </p>
                        <p style={{ fontSize: '1rem' }}>
                            {job.remote ? (
                                <>
                                    <BsGlobe className='mr-2' fontSize='1.1rem' /> Employees Can
                                </>
                            ) : (
                                <>
                                    <HiOfficeBuilding className='mr-2' fontSize='1.1rem' />
                                    Employees Can't
                                </>
                            )}{' '}
                            Work Remotely
                        </p>
                    </div>
                </Modal.Header>
                <Modal.Body className='w-70 mx-auto'>
                    {/* <Input
                        name='fullName'
                        type='text'
                        label='Full Name'
                        placeholder='Enter your full name'
                        required
                    /> */}
                    <Input
                        name='resume'
                        type='file'
                        label='Resume/CV (PDF, Image, Word, TXT, 10MB Maximum)'
                        onChange={handleResumeChange}
                        required
                    />
                    <Input
                        name='jobTitle'
                        type='text'
                        label='Current/Previous Job Title'
                        placeholder='Enter your Job Title'
                        required
                    />
                    <Input
                        name='experience'
                        type='number'
                        label='Years of Experience'
                        placeholder='Enter your experience in year'
                        required
                    />
                    <div className='mb-2'>
                        Country Code <span class='text-danger'>*</span>
                    </div>
                    <Autocomplete
                        freeSolo
                        disableClearable
                        fullWidth
                        getOptionLabel={option => myCountryCodesObject[option]}
                        onChange={(event, newValue) => {
                            handlers.setValues({ countryCode: newValue });
                        }}
                        options={Object.keys(myCountryCodesObject)}
                        sx={{
                            mb: 2,
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                placeholder='Country code'
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        fontFamily: 'sans-serif',
                                    },
                                }}
                                fullWidth
                                size='small'
                                name='countryCode'
                            />
                        )}
                    />
                    <FormHelperText error={Boolean(errors.countryCode)}>
                        {errors.countryCode}
                    </FormHelperText>
                    <Input
                        name='phone'
                        type='number'
                        label='Phone Number'
                        placeholder='Enter your Phone number'
                        required
                    />
                    <Input
                        type='text'
                        name='linkedinAccount'
                        label='LinkedIn Account (https://example.com)'
                        placeholder='Enter your LinkedIn Account '
                    />
                    <Input
                        name='photo'
                        type='file'
                        label='Upload a Photo (6MB Maximum)'
                        onChange={handlePhotoChange}
                        required
                    />
                    <BootstrapForm.Check
                        className='d-inline'
                        onChange={() => setCheckBox(prev => !prev)}
                    />{' '}
                    I have read and agree to the Privacy Notice
                </Modal.Body>
                <Modal.Footer className='w-70 mx-auto mb-4'>
                    <Button variant='secondary' className='mr-2' onClick={() => navigate(-1)}>
                        Cancel
                    </Button>

                    <Submit>
                        {loader => (
                            <Button type='submit' variant='primary' disabled={loader || !checkBox}>
                                Apply for Job {loader}
                            </Button>
                        )}
                    </Submit>
                </Modal.Footer>
            </HookForm>

            <Modal show={show} onHide={handleClosePopUp}>
                <Modal.Header closeButton>
                    <h5>Applied Successfully</h5>
                </Modal.Header>
                <Modal.Body>
                    You have successfully applied for this job. We will contact you back on your
                    email address, please stay tuned.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleCloseButton}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    ) : (
        <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '70vh' }}>
            <Spinner className='mr-2' />
            Please wait, Loading the Job Details...
        </div>
    );
}

function CustomInput(props) {
    const { label, type, id, helperText, error, required = '', ...rest } = props;

    return (
        <>
            <BootstrapForm.Label htmlFor={id} style={{ marginBottom: '0.5rem' }}>
                {label} {required && <span className='text-danger'>*</span>}
            </BootstrapForm.Label>
            <BootstrapForm.Control type={type} id={id} {...rest} />
            <BootstrapForm.Text className='text-danger d-block mb-4'>
                {helperText}
            </BootstrapForm.Text>
        </>
    );
}

export default ApplyForm;
