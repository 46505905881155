import React from 'react';
import Container from 'react-bootstrap/Container';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import Image from '../components/Image';
import { Link } from 'react-router-dom';
import useSnack from '../hooks/useSnack';
import { createContext, useContext } from 'react';
// import Sidebar from './Sidebar';
// import Appbar from './Appbar';
const SnackContext = createContext();

const Navbar = props => {
    // const [show, setShow] = useState(false);
    // const toggleButton = useRef(null);
    const { SnackBar, showMessage } = useSnack();

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const page = useMemo(() => {
    //     return Apps.find(link => link.to === location.pathname);
    // }, [location.pathname]);

    // const closeAppsMenu = () => {
    //     const button = toggleButton.current;
    //     const isOpen = Array.from(button.classList).includes('show');
    //     if (isOpen) button.click();
    // };

    // useEffect(() => {
    //     handleClose();
    //     closeAppsMenu();
    // }, [location.pathname, location.hash]);

    // const logout = () => {
    //     clearCookie('accessToken');
    //     clearCookie('role');
    //     // authorize(false);
    //     const redirectTo =
    //         env('AUTHENTICATION_CLIENT') +
    //         '/login?redirectto=' +
    //         encodeURIComponent(env('DOMAIN') + location.pathname + location.search);
    //     window.location.replace(redirectTo);
    // };

    return (
        <>
            <BootstrapNavbar
                bg='white'
                className='shadow-sm bg-body-tertiary rounded'
                sticky='top'
                expand='xs'
                style={{ padding: 0 }}>
                <Container
                    fluid
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <div className='d-inline-flex'>
                        {/* <BootstrapNavbar.Toggle
                            aria-controls={`offcanvasNavbar-expand-lg`}
                            onClick={handleShow}
                            style={{
                                border: 0,
                                outline: 0,
                                boxShadow: 'none',
                                marginRight: 0,
                            }}
                        /> */}
                        <Link
                            to='/'
                            style={{
                                color: '#5f6368',
                                marginLeft: '16px',
                                display: 'flex',
                                marginBottom: '8px',
                                marginTop: '8px',
                            }}>
                            <BootstrapNavbar.Brand
                                className='p-0 d-inline-flex align-items-end'
                                style={{ fontSize: '23px' }}>
                                <Image src='logo.jpg' style={{ height: '46px' }} />
                                <div className='ms-1'>
                                    {/* <span style={{ fontWeight: 600 }}>Catch </span> */}
                                    <span style={{ fontWeight: 400 }}>Careers</span>
                                </div>
                            </BootstrapNavbar.Brand>
                        </Link>
                    </div>
                    {/* <div className='d-none d-sm-inline-flex align-items-center justify-self-end'> */}
                    {/* <Dropdown align='end'>
                            <Dropdown.Toggle id='dropdown-basic' ref={toggleButton}>
                                <TbGridDots size='25px' className='mx-4' />
                            </Dropdown.Toggle>
                            <Appbar />
                        </Dropdown> */}

                    {/* {user ? (
                            <Avatar
                                onClick={handleClickMenu}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    border: '1px solid rgba(0, 0, 0, 0.5)',
                                }}>
                                {user?.firstName.charAt(0)}
                            </Avatar>
                        ) : null}
                    </div>
                    <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
                        <MenuItem onClick={logout}>
                            <Typography fontWeight={200}>Logout</Typography>
                        </MenuItem>
                    </Menu> */}

                    {/* <Sidebar show={show} handleClose={handleClose} /> */}
                </Container>
            </BootstrapNavbar>
            <Container
                style={{
                    maxWidth: 'min(90%, 1296px)',
                    minHeight: 'calc(100vh - 330px)',
                    marginInline: 'auto',
                    padding: 0,
                }}>
                <SnackContext.Provider value={showMessage}>{props.children}</SnackContext.Provider>
            </Container>
            {SnackBar}
        </>
    );
};

const useMessage = () => {
    const showMessage = useContext(SnackContext);

    function showSuccess(msg) {
        showMessage({ abc: msg });
    }

    function showError(msg) {
        showMessage({ danger: msg });
    }

    return { showError, showSuccess };
};

export default Navbar;
export { useMessage };
